import './App.css';
import StartLogo from './startlogo/StartLogo.js'
import Why2 from './why-us/why-us.js'
import Pricing from './pricing/Pricing.js'
import TuoteKehitys from './tuotekehitys/TuoteKehitys.js'
import ChatBots from './chatbots/ChatBots.js'
import Contact from '../components/contact/Contact.js'
import { Helmet, HelmetProvider } from 'react-helmet-async';


function App() {
  return (
    <div className="App">
       <HelmetProvider>
          <Helmet>
            <link rel="canonical" href="https://emeric.ai" />
            <meta name="description" content="Emeric.ai - Rakennamme kustomoidun chatbotin asiakaspalvelun sekä liidien generointiin tekoälyn avulla juuri sinulle"/>
            <title>Emeric.ai - asiakaspalvelu chatbotit kustomoituna firmallesi</title>
          </Helmet>
          <StartLogo/>
          <Why2/>
          <TuoteKehitys/>
          <ChatBots/>
          <Contact/>
        </HelmetProvider>

      </div>
  )
}

export default App;
