import './StartLogo.css'
import kuva2 from '../../example2.png'
import exampleLogo from '../../eai.png'
import { BsFillPersonPlusFill } from "react-icons/bs";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { VscSmiley } from "react-icons/vsc";

let titles=[
    "ÄLÄ jää liideistä paitsi",
    "KASVATA myyntiäsi",
    "PALVELE asiakkaitasi"]
let texts=["Kerää liidejä 24/7","Vastaa asiakkaiden tarpeisiin vuorokauden ympäri","Markkinoiden nopein ja laadukkain asiakaspalvelu Emeric.ai:lla"]
let iconList=[<BsFillPersonPlusFill className='startPageIcon'/>,<FaMoneyBillTrendUp className='startPageIcon'/>,
    <VscSmiley className='startPageIcon'/>]


const InfoTable=()=>{
    return ( 
    <div className="startInfoTable">
        {titles.map((n,i)=>{
            let title1=n.split(" ")[0]
            let title2=n.replace(title1,"")
            return(
                <div className='fullInfoStartTable'>
                    {iconList[i]}
                    <div className="oneStartTable">
                        <div><span>{title1} </span> {title2}</div>
                        <div> <strong>&bull; </strong> <span>{texts[i]}</span> </div>
                    </div> 

                </div>
            )
        })}
    </div>
    )
}
  

const StartLogo = () =>{

    const redirectToContact = () => {
        const targetElement = document.getElementById('Contact');
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
        }
    };
    
    return (
        <div className='StartLogo'>
            <div className='startdiv1'>
                <img src={exampleLogo} alt="Emeric.ai Logo" title="Emeric.ai Logo" className='startLogoPic'></img>
                <h1>Automatisoi asiakaspalvelusi tekoälyllä!</h1>
            </div>
            <div classname="startSometi">
                <InfoTable/>
            </div>

            <div className="startimgContainer">
                    <img src={kuva2} alt="customer serivece chat example" title="customer service chat" className='examplePicStart'></img>
                    <div onClick={()=>redirectToContact()}>Varaa ilmainen demo</div>

            </div>

            
        </div>
    )
}
export default StartLogo