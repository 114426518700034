import './TuoteKehitys.css'
import { FaHandshake } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';


const TuoteKehitys = () => {
    const navigate = useNavigate()


    const redirectToTuotePromise = () => {
        return new Promise((resolve, reject) => {
            navigate('/tuotekehitys');
            resolve()
        })
    };

    const handleClick = ()=>{
        redirectToTuotePromise().then(n=>{
            const targetElement = document.getElementById('tuoteKW');
            targetElement.scrollIntoView({ behavior: 'smooth' });
        })        
    }

    return(
        <div className="TuoteKehitys">
            <div className='TuoteKehitysLeft'>
                <div className='TuoteKehitysLeftTitle'>
                    Tuotekehityskumppaniksi?
                </div>
                <div className='TuoteKehitysLeftText'>
                Etsimme yrityksiä mukaan yhteistyöhön kehittämään tuotettamme paremmaksi. Haluamme auttaa kehittämään asiakaspalveluanne ja löytämään teille älyllisempiä ratkaisuja.
                </div>
                <div className='TuoteKehitysButton' onClick={handleClick}>
                    Lue lisää!
                </div>

            </div>
            <div className='TuoteKehitysRight'>
                <FaHandshake className='handShake'/>
            </div>
        </div>
    )
}

export default TuoteKehitys