import React from 'react';
import Contact from "../../components/contact/Contact";
import { HiMiniArrowsRightLeft } from "react-icons/hi2";
import './TuoteKehitysWindow.css'
import { Helmet, HelmetProvider } from 'react-helmet-async';

const TuoteKehitysWindow = () => {
    return (
        <HelmetProvider>
            <Helmet>
                <link rel="canonical" href="https://emeric.ai/tuotekehitys" />
                <meta name="description" content="Emeric.ai tarjoaa yrityksille mahdollisuuden osallistua tuotekehitykseemme mahtavilla hyödyillä."/>
                <title>Emeric.ai - Tuotekehitys kanssamme</title>
            </Helmet>
            <div className="tuoteKehitysWindow">
                <div className="tuoteKehitysContent" id="tuoteKW">
                    <div className="leftPanel"> 
                        <HiMiniArrowsRightLeft className="iconStyle" />
                        <div className="titleText">Mukaan tuotekehitykseen?</div>
                    </div>
                    <div className="contentArea">
                        <h1 className="contentHeader">Tuotekehitys?</h1>
                        <h2 className="textContent">
                            Etsimme yrityksiä yhteistyöhön kehittämään chatbottia, jonka tehtävänä on asiakaspalvelu, sekä myynnin tehostaminen. Haluamme selvittää yrityksenne kipupisteet ja päästä ratkaisemaan niitä tekoälychatbotin avulla. Hypätkää mukaan tulevaisuuteen ensimmäisten joukossa!
                        </h2>
                        <div className="contentHeader">Hyöty teille?</div>
                        <h3 className="textContent">
                            Esitätte meille kipuspisteitä koskien asiakaspalvelua, vastineeksi saatte edulliset ratkaisut chatbotin avulla. Saatte suuren vaikutusvallan siihen miten chatbot toimii ja mihin ongelmiin se tuo ratkaisun. Tuotekehityskumppanuus mahdollistaa teille entistä edullisemmat hinnat!
                        </h3>
                    </div>
                </div>
                <Contact/>
            </div>

        </HelmetProvider>
    )
}

export default TuoteKehitysWindow;
