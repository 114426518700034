import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import App from './mainpage/App';
import LoginPage from './pages/login-page/LoginPage'
import Dashboard from './pages/dashboard-page'
import ChatBotsWindow from './pages/chatbots-page/ChatBots-page'
import { useEffect } from 'react';
import {jwtDecode} from 'jwt-decode';
import axios  from 'axios'
import Forgot from './pages/forgot-page/Forgot'
import Reset from './pages/reset-page/Reset'
import TuoteKehitysWindow from './pages/tuotekehitys-page/TuoteKehitysWindow'
import { url } from "./url";
import Header from './components/header'
import Footer from './components/footer/Footer';
import NotFound from './components/not-found/NotFound';
import PrivacyPolicy from './pages/privacy-policy/PrivacyPolicy';

function isTokenNotExpired(token) {
  try {
    const currentTime = Math.floor(Date.now() / 1000)
    if (token.exp < currentTime) {
        return false;
    } else {
      return true;
    }
  } catch (error) {
    return false
  }
}

const R = () => {
  const [chats, setChats] = useState(null)
  const [loggedIn,setLoggedIn]=useState(false)

  useEffect(() => {
    if(!loggedIn){
      let token=localStorage.getItem("authorizationViralTop")
      if(token){
        if(isTokenNotExpired(jwtDecode(token))){
          setLoggedIn(true)
        }
      }
    }
  }, []);

  useEffect(() => {
    if(chats===null && loggedIn){
      fetchDashboard();
    }
  }, [loggedIn]);

const fetchDashboard = () => {
  axios.get(`${url}/dashboard`,{
    headers: {
        Authorization: `Bearer ${localStorage.getItem('authorizationViralTop')}`
      }
  })
    .then(response => {
      setChats(response.data);
    })
    .catch(error => {
        
    });
};


  return (
    <Router>
      <Header loggedIn={loggedIn} setLoggedIn={setLoggedIn} />
      <Routes>
        <Route exact path="/" element={<App/>} />
        <Route path="/chatbots" element={<ChatBotsWindow/>}/>
        <Route path="/dashboard" element={<Dashboard chats={chats} loggedIn={loggedIn}/>}/>
        <Route path="/forgot" element={<Forgot/>}/>
        <Route path="/login" element={<LoginPage loggedIn={loggedIn} setLoggedIn={setLoggedIn}/>} />
        <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
        <Route path="/reset" element={<Reset/>}/>
        <Route path="/tuotekehitys" element={<TuoteKehitysWindow/>}/>
        <Route path="*" element={<NotFound/>} status={404} />
      </Routes>
      <Footer/>
    </Router>
  );
};

export default R;
