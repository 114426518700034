import './ChatBots.css'
import { BsChat } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
import kuva from '../../dash.webp';

const ChatBots=()=>{
    const navigate=useNavigate()

    const redirectToChatbots = () => {
        return new Promise((resolve, reject) => {
            navigate('/chatbots');
            resolve()
        })    
    };

    const handleChatBotPress= () =>{
        const targetElement = document.getElementById('chatbotTableHolder');
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
        } else {
            redirectToChatbots().then(n=>{
                const targetElement = document.getElementById('chatbotTableHolder');
                targetElement.scrollIntoView({ behavior: 'smooth' });
            })
        }
    }
    return(
        <div className='ChatBots'>
            <div className="chatBotsFirstHalf">
                <h6 className='chatBotTitle'>Chatbottimme</h6>
                <div className='chatBotText'>
                    Meidän chatbottimme pystyvät käymään keskustelua avoimien viestien avulla. Emme tukeudu ainoastaan etukäteen määritettyihin keskustelukortteihin, vaan rakennamme chatbottimme keskustelemaan kuten ihminen. Chatbottimme tietokantana toimii teidän määrittämä data, jonka pohjalta tekoäly käy keskustelua käyttäjän kanssa.
                </div>
                <div className="chatbotitButton" onClick={()=>handleChatBotPress()}><BsChat/>Chatbotit</div>
            </div>
            <div className="chatBotsSecondHalf">
                <img className="chatBotPic" title="pic" alt="pic" loading="lazy" src={kuva}/>
                <div className='dashText'> Pystyt seuraamaan chatbottisi vastauksia ja tilastoja sivuiltamme kirjautumalla ja menemällä dashboard osioon.</div>
            </div>
        </div>
    )
}
export default ChatBots