import './NotFound.css'
import { Helmet, HelmetProvider } from 'react-helmet-async';

const NotFound = () => {
    return (
      <HelmetProvider>
        <Helmet>
            <meta name="description" content="This page does not exist"/>
            <title>Page not found - Emeric.ai </title>
        </Helmet>
        <div className="notFound1">
          <div className="notFound2">
            404 - Page Not Found
          </div>
        </div>
      </HelmetProvider>

    )
  };

export default NotFound