import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, LineController, LineElement, PointElement, LinearScale, Title,CategoryScale,Tooltip ,Legend} from 'chart.js';

Chart.register(LineController, LineElement, PointElement, LinearScale, Title, CategoryScale,Legend,Tooltip);

const MyChart = ({label,chats}) => {

  const data = {
    labels:  chats.map(n=>n.date)
    ,
    datasets: [
      {
        label: label,
        data: chats.map(n=>n.chats),
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        pointRadius: 5,
        pointHoverRadius: 7,
        pointHitRadius: 10,
      },
    ],
  };



  return (
    <div>
      <Line
        data={data}
        style={{height: "250px"}}
        options={{
          scaleShowVerticalLines: false,
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: 
              {
                border: {
                  display: false,
                },
                grid: {
                  display: false,
                },
/*                 time: {
                  unit: 'day',
                  displayFormats: {
                    day: 'YYYY-MM-DD',
                  },
                }, */
                scaleLabel: {
                  display: true,
                  labelString: 'Date',
                },
              },
            
            y: 
              {
                border: {
                  display: false,
                },
                ticks: {
                  beginAtZero: true,
                },
                scaleLabel: {
                  display: true,
                  labelString: 'Data',
                },
                
              },
            
          },
/*           plugins: {
            tooltip: {
              enabled: true,
              callbacks: {
                title: function (tooltipItem) {
                  return tooltipItem[0].label;
                },
 
              },
            },
          }, */
        }}
      />
    </div>
  );
};

export default MyChart;
