
import './PrivacyPolicy.css'
import { Helmet, HelmetProvider } from 'react-helmet-async';

const PrivacyPolicy = () =>{

    return(
        <HelmetProvider>
            <Helmet>
                <link rel="canonical" href="https://emeric.ai/privacy-policy" />
                <meta name="description" content="Emeric.ai privacy policy, all you need to know about our data collection and usage."/>
                <title>Emeric.ai - Privacy policy</title>
            </Helmet>
        <div className='privacy1' id="privacy-policy">
            <div className='privacy2'>
                <div className='privacyHeader' id="privacy-policy-headline">Emeric.ai (ViralTop Oy) tietosuojaseloste</div>
                <div style={{marginTop:"40px"}}>
                    Emeric.ai on ViralTop Oy:n aputoiminimi. Tämä tietosuojaseloste koskee Emeric.ai:n ja ViralTop Oy:n alaisuudessa käsiteltäviä tietoja. Noudatamme EU:n Yleisen tietosuoja-asetuksen määräyksiä.
                </div>

                <div style={{display:"flex",flexDirection:"column",marginTop:"30px"}}>
                    <div>
                        <span className='fullHeadLine'>1)  <span className='underline'> Määritelmät:</span> </span> <br/><br/><br/>
                        <div className='margleft'>
                            Tässä tietosuojaselostessa tarkoitetaan,<br/> <br/>
                            <span className='bold'>Käyttäjällä</span><br/>
                            <span className='margleft'>
                            Henkilöä, joka käyttää jotakin ViralTop Oy:n tarjoamaa palvelua ViralTop Oy:n tai
                            Yritysasiakkaamme verkkosivujen, sosiaalisen median kanavan, matkapuhelimen,
                            sähköpostin tai muun vastaavan välityksellä.<br/> <br/>
                            </span>

                            <span className='bold'>Yritysasiakkaalla:</span><br/>
                            <span className='margleft'>
                            Yhteisöä, jolle tuotamme ViralTop Oy:n tarjoamia palveluita. Esimerkiksi toinen osakeyhtiö, jonka verkkosivuille olemme asentaneet ViralTop Oy:n tuottaman chatbotin. 
                            </span>

                            <br/> 
                            <span className='bold'>Välillisellä asiakkalla:</span>  <br/> 
                            <span className='margleft'>
                            Henkilöä, joka käyttää ViralTop Oy:n tarjoamaa palvelua välillisesti yritysasiakkamme sivujen, sosiaalisen median, matkapuhelimen, sähköpostin tai muun vastaavan välityksellä. 
                            </span>

                            <br/>
                            <span className='bold'>Henkilötiedoilla: </span> <br/> 
                            <span className='margleft'>
                            Henkilötiedot määritellään tässä tietosuojaselosteessa siten, kuin se on EU:n Yleisen tietosuoja-asetuksessa 2016/69 (GDPR) määritelty
                            </span>
                            <br/>
                            <span className='bold'>Tietojen käsittelyllä</span> <br/> 
                            <span className='margleft'>
                            Tietojen käsittelyllä määritetään tässä tietosuojaselosteessa siten, kuin se on EU:n Yleisen tietosuoja-asetuksessa 2016/69 (GDPR) määritelty.
                            </span>

                            <br/>
                            <span className='bold'>EU:n Yleinen tietosuoja-asetuksella: </span>  <br/>
                            <span className='margleft'>
                                EUROOPAN PARLAMENTIN JA NEUVOSTON ASETUSTA (EU) 2016/679 luonnollisten henkilöiden suojelusta henkilötietojen käsittelyssä sekä näiden tietojen vapaasta liikkuvuudesta ja direktiivin 95/46/EY kumoamisesta (yleinen tietosuoja-asetus)
                            </span>
                            <br/>
                            <span className='bold'>Rekisteröidyllä: </span> <br/> 
                            <span className='margleft'>
                            Luonnollista henkilöä, jonka henkilötietoja mahdollisesti käsittelemme ViralTop Oy:n hallinoimissa rekistereissä tässä tietosuojaselostessa mainittuihin tarkoituksiin. 
                            </span>

                            <br/>
                            <span className='bold'>Ulkopuolisella taholla: </span>  <br/>
                            <span className='margleft'>
                            Ulkopuolisella taholla tarkoitetaan sellaisia tahoja, joille palvelumme käyttäjä ei ole antanut suostumusta käyttäjän henkilötietojensa käsittelyyn.
                            </span>
                            <br/> <br/>
                        </div>


                    </div>
                    <span className='fullHeadLine'>2)  <span className='underline'> Tietojen käsittelyn peruste</span> </span> <br/><br/>
                    <div className='margleft'>
                    Kaikki ViralTop Oy:n suorittama tietojen rekisteröinti ja käsittely perustuu käyttäjän rekisteröidyn suostumukseen. Ennen henkilötietojen ja yhteytietojen luovuttamista pyydämme käyttäjän suostumusta tietojen keräämiseen. Käyttäjälle annetaan mahdollisuus tutustua tähän tietosuojaselosteeseen suostumuksen antamisen yhteydessä. 
                    </div>
                    <br/> <br/>

                    <span className='fullHeadLine'>3)  <span className='underline'> Käsittelemämme tiedot</span> </span> <br/><br/>
                    <div className='margleft'>

                        Tiedot joita mahdollisesti käsitelemme ovat: <br/><br/>
                        <div className='margleft'>
                            <span className='bold'>• etunimi ja sukunimi</span> <br/>
                            <span className='bold'>• Sähköpostiosoite </span><br/>
                            <span className='bold'>• Matkapuhelinnumero </span><br/>
                            <span className='bold'>• Yrityksen / Yhteisön nimi </span><br/>
                            <span className='bold'>• Viestit, jotka sisältyvät käyttäjän käymän keskustelun tarjoamamme palvelun 
                            välityksellä. </span><br/> <br/>
                        </div>
                    </div>

                    <span className='fullHeadLine'>4)  <span className='underline'> Tietojen käsittelyn tarkoitus</span> </span> <br/><br/>
                    <div className='margleft'>

                        Käsittelemme tietoja: <br/>
                        <div className='margleft'>
                            <div style={{display:"flex",flexDirection:"row",gap:"5px"}}> 
                                <span className='bold'>•</span>
                                <div> tarjotaksemme palveluamme yritysasiakkaille, sekä välillisille asiakkaille. <br/></div>
                            </div>


                            <div style={{display:"flex",flexDirection:"row",gap:"5px"}}> 
                                <span className='bold'>•</span>
                                <div> seurataksemme ja parantaaksemme palvelumme käyttöä, toimivuutta, laatua, ja vastuullisuutta <br/></div>
                            </div>


                            <div style={{display:"flex",flexDirection:"row",gap:"5px"}}> 
                                <span className='bold'>•</span>
                                <div> tarjotaksemme yritysasiakkaillemme asiakastietoa, jota he voivat hyödyntää 
                                    markkinointi-, suoramarkkinointi- ja myyntitoimenpiteissään, parantaakseen
                                    liiketoimintansa tuloksellisuutta ja tehokkuutta.<br/><br/>
                                </div>
                            </div>
                        </div>
                        
                    </div>

                    <span className='fullHeadLine'>5)  <span className='underline'> Tietojen käsittelyn ajallinen kesto</span> </span> <br/><br/>
                    <div className='margleft'>

                        Käsittelemme henkilötietoja kohdassa 4) listattuihin tarkoituksiin, henkilötietojen käsittely
                        jatkuu sen ajanjakson, joka on tarpeen näiden tarkoitusten täyttämiseksi. <br/><br/>
                        Tietojen käsittelymme perustuu rekisteröidyn suostumukseen. Mikäli rekisteröity
                        peruuttaa suostumuksensa, ja ilmoittaa siitä ViralTop Oy:lle, poistamme
                        hallinoimastamme rekisteristä välittömästi ne henkilötiedot, joiden osalta rekisteröity
                        peruutta suostumuksensa.
                    </div><br/><br/>

                    <span className='fullHeadLine'>6)  <span className='underline'> Rekisteröidyn oikeudet:</span> </span> <br/><br/>
                    <div className='margleft'>

                        Rekisteröidyllä on oikeus: <br/><br/>
                        <span className='margleft'>
                                <span className='bold'>• saada tiedot poistetuksi </span><br/>
                                <span className='margleft'>
                                    Rekisteröity voi pyynnöllään saada omat tietonsa poistetuksi ViralTop Oy:n
                                    hallinoimista rekistereistä. <br/><br/>
                                </span>
                        </span>
                        <span className='margleft'>
                            <span className='bold'>• vastustaa käsittelyä </span><br/>
                            <span className='margleft'>
                                Palvelumme käyttäjällä on ennen rekisteröimistä mahdollisuus kieltäytyä
                                henkilötietojen ja yhteystietojen luovuttamiseen. <br/><br/>
                            </span>
                        </span>
                        <span className='margleft'>
                            <span className='bold'>• saada pääsy omiin tietoihin </span><br/>
                            <span className='margleft'>
                                Rekisteröity voi pyynnöllään saada tarkastella omia tietojaan Hallinoimista 	 	 	
                                rekistereistä. <br/><br/>
                            </span>
                        </span>

                        <span className='margleft'>
                            <span className='bold'>• tietojen oikaisemiseen </span><br/>
                                <span className='margleft'>
                                Rekisteröity voi pyynnöllään saada omat tiedot oikaistuksi, mikäli kyseiset tiedot ovat
                                ViralTop Oy:n hallinoimissa rekistereissä rekisteröity virheellisesti. <br/><br/>
                                </span>
                        </span>

                        <span className='margleft'>
                            <span className='bold'>• siirtää tiedot järjestelmästä toiseen </span> <br/>
                            <span className='margleft'>
                                Rekisteröidyllä on oikeus saada häntä koskevat henkilötiedot, jotka hän on toimittanut
                                rekisterinpitäjälle, jäsennellyssä, yleisesti käytetyssä ja koneellisesti luettavassa
                                muodossa, siirretyksi toiselle rekisterinpitäjälle. <br/><br/>
                            </span>
                        </span>

                        <span className='margleft'>
                        <span className='bold'>• rajoittaa tietojen käsittelyyn </span><br/>
                            <span className='margleft'>

                                Kaikki suoritamamme tietojen käsittely perustuu suostumukseen. Rekisteröidyllä on
                                mahdollisuus päättää mitä tietoja hän haluaa luovuttaa ViralTop Oy:n rekisteröitäväksi,
                                mikäli hän haluaa antaa suostumuksensa vain tiettyjen henkilötietojen luovuttamiseksi.  <br/><br/>
                            </span>
                        </span>
                    </div>

                    <span className='fullHeadLine'>7)  <span className='underline'> Käsittelyn turvallisuus</span> </span> <br/>
                    <div className='margleft'>
                        <span className='bold'> • Salassapito </span><br/>
                        <div className='margleft'>
                            ViralTop Oy:n henkilöstöä sitoo salassapitovelvollisuus, joka kattaa kaikkien
                            rekisteröimiemme henkilötietojen käsittelyn. Salassapitovelvollisuus sisältää kiellon
                            henkilötietojen luovuttamisesta, sekä tiedonannosta ulkopuolisille tahoille. <br/> <br/>
                        </div>
                        <span className='bold'>• Tietosuojan toteuttaminen </span><br/>
                        <div className='margleft'>
                            Pääsy rekisteröityihin tietoihin edellyttää erityistä lupaa, jonka saaneille henkilöille
                            annetaan salasana kyseiseen tietokantaan, jossa tiedot ovat tallennettuina. Luvan
                            saaneita tahoja ovat vain ne tahot, joille käyttäjä on antanut suostumuksensa tietojensa
                            käsittelemiseksi. Tämän menettelyn avulla varmistetaan, että rekisteröityihin tietoihin eivät
                            ulkopuoliset tahot pääse käsiksi. <br/><br/>
                        </div>
                    </div>


                    <span className='fullHeadLine'>8)  <span className='underline'> Yhteystiedot</span> </span> <br/><br/>
                    <div className='margleft'>
                        Lisäkysymyksiä, sekä rekisteröidyn pyyntöjä koskien rekisteröidyn oikeuksia ViralTop
                        Oy:n tietosuojasta voi kysyä ja pyytää alla mainittujen yhteystietojen kautta. <br/> <br/>
                        <div className='margleft'>
                            <span><span className='bold'>&bull; Sähköposti: </span> info@emeric.ai </span><br/>
                            <span><span className='bold'>&bull; Puhelinnumero: </span> 0407441877 </span><br/>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        </HelmetProvider>

    )
}

export default PrivacyPolicy
