
import styles from './Header.module.css'
import { BsChat } from "react-icons/bs";
import { TbDeviceAnalytics } from "react-icons/tb";
import { IoCallOutline } from "react-icons/io5";
import { IoIosLogIn } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import kuva4 from '../../eai.png'

const Header = ({setLoggedIn,loggedIn}) => {

    const handleLogOutClick= () => {
        localStorage.removeItem("authorizationViralTop")
        setLoggedIn(false)
    }

    const navigate = useNavigate();

    const redirectToHome = () => {
        navigate('/');
    };

    const redirectToHomePromise = () => {
        return new Promise((resolve, reject) => {
            navigate('/');
            resolve()
        })
    };

    const redirectToLogin = () => {
        return new Promise((resolve, reject) => {
            navigate('/login');
            resolve()
        })
    };


    const redirectToChatbots = () => {
        return new Promise((resolve, reject) => {
            navigate('/chatbots');
            resolve()
        })    
    };

    const handleChatBotPress= () =>{
        const targetElement = document.getElementById('chatbotTableHolder');
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
        } else {
            redirectToChatbots().then(n=>{
                const targetElement = document.getElementById('chatbotTableHolder');
                targetElement.scrollIntoView({ behavior: 'smooth' });
            })
        }
    }

    const handleContactPress = () => {
        const targetElement = document.getElementById('Contact');
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
        } else {
            redirectToHomePromise().then(n=>{
                const targetElement = document.getElementById('Contact');
                targetElement.scrollIntoView({ behavior: 'smooth' });
            })
        }
    }

    const handleLoginPress=()=>{
        redirectToLogin().then(n=>{
            const targetElement = document.getElementById('LoginPage');
            targetElement.scrollIntoView({ behavior: 'smooth' });
        })

    }

    return(
        <header>
                <img className={styles.Headerlogo} onClick={redirectToHome} alt="logo" title="logo" loading='lazy' src={kuva4}/>
                <div className={styles.headerButton} onClick={handleContactPress}><IoCallOutline/>Ota yhteyttä</div>
                <div className={styles.headerButton} onClick={handleChatBotPress}><BsChat/>Chatbotit</div>
                <a className={styles.headerButton} href="/dashboard"><TbDeviceAnalytics/>Dashboard</a>

                {
                    !loggedIn &&  <div className={styles.headerLogIn} onClick={handleLoginPress}><IoIosLogIn />Login</div>

                }
                {
                    loggedIn &&  <div className={styles.headerLogIn}  onClick={handleLogOutClick}><IoIosLogIn/>Log out</div>

                }

        </header>
    )

}
export default Header