

import {useEffect, useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import { url } from "../../url";
import { Helmet, HelmetProvider } from 'react-helmet-async';


const Reset = ()=>{
    const [error,setError]=useState("")
    let navigate = useNavigate()

    useEffect(() => {
        const extractTokenFromURL = () => {
          const urlParams = new URLSearchParams(window.location.search);
          const token = urlParams.get('token');
          if (token) {
            localStorage.setItem('accessToken', token);
          }
        };
    
        extractTokenFromURL()
      }, [])


    const handleSetNewPW =()=>{
        let p1=document.getElementById("newpw1").value
        let p2=document.getElementById("newpw2").value
        if(p1 !== p2){
            setError("salasanat ei täsmää")
            setTimeout(()=>{
                setError("")
            },3000)
        }
        else if(p1.length<5){
            setError("Salasanan täytyy olla yli 5 kirjainta")
            setTimeout(()=>{
                setError("")
            },3000)
        }
        else{
            let token=localStorage.getItem("accessToken")
            console.log(token)
            axios.post(`${url}/reset`,{password:p1, token:token})
                .then(n=>{
                    setError("salasana vaihdettu!")
                    setTimeout(()=>{
                        navigate("/login")
                    },3000)
                })
                .catch(n=>{
                    setError("error")
                    setTimeout(()=>{
                        setError("")
                    },3000)
                })

        }
    }

    const handlePWchange = (e) => {
        if (e.code === 'Enter') {
            handleSetNewPW()
        }
    }


    return(
        <HelmetProvider>
            <Helmet>
                <link rel="canonical" href="https://emeric.ai/reset" />
                <meta name="description" content="Reset your password with the link from your email"/>
                <title>Reset password</title>
            </Helmet>
        <div>
            <div style={{"marginTop":"200px","width":"100%","height":"300px","display":"flex","alignItems":"center",
            "justifyContent":"center"}}>
                <div style={{"display":"flex","flexDirection":"column","border":"2px solid black"
            ,"width":"40%","alignItems":"center"}}>
                    <div style={{"display":"flex","flexDirection":"row"}}>
                        <div style={{"padding":"8px"}}>Uusi salasana</div>
                        <input id="newpw1" style={{"margin":"8px"}}/>
                    </div>
                    <div style={{"display":"flex","flexDirection":"row"}}>
                        <div style={{"padding":"8px"}}>Toista salasana</div>
                        <input id="newpw2" onKeyPress={handlePWchange} style={{"margin":"8px"}}/>
                    </div>
                    <button onClick={handleSetNewPW} style={{"width":"100px","marginTop":"20px","marginBottom":"20px"}}>tallenna</button>
                    <div style={{"marginBottom":"10px","color":"red"}}>{error}</div>
                </div>
            </div>
        </div>
        </HelmetProvider>
    )

}


export default Reset