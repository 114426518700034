import { useState } from "react"
import { VscTriangleDown } from "react-icons/vsc";
import { VscTriangleUp } from "react-icons/vsc";
import styles from './ChatHolder.module.css'

function isSameDay(date1, date2) {
    return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate()
    );
}



const ChatHolder=({chats})=>{
    const [selectedChat,setSelectedChat]=useState(false)
    const [filteredDates,setFilteredDates]=useState([])
    const [showDemoChats,setShowDemoChats]=useState(false)

    let today=new Date()

    function handleFilterClick(date){
        if(filteredDates.includes(date)){
            setFilteredDates(prev=>prev.filter(n=>n !== date))
        }
        else{
            setFilteredDates(prev=>prev.concat(date))
        }
    }

    function handleChatClick(chat){
        if(selectedChat === chat){
            setSelectedChat(false)
        }
        else{
            setSelectedChat(chat)
        }
    }

    let chatDict={}
    chats.filter(n=>showDemoChats || !n.local).slice().reverse().forEach((n,i)=>{
        let date = n.id.split("T")[0]
        if(!chatDict[date]){
            chatDict[date]={chats:[n]}
        }
        else{
            chatDict[date].chats.push(n)
        }
    })


    const handleDemoChange=(e)=>{
        setShowDemoChats(prev=>!prev)
    }
    
    return(
        <div className={styles.container}>
            <div>
                <div className={styles.demoBox}>
                    <span>Näytä demo chatit</span>
                    <input type="checkbox" checked={showDemoChats} onChange={handleDemoChange}/>
                </div>
                <div className={styles.chatList}>
                    {Object.keys(chatDict).map(n=>{
                        let curObject=chatDict[n]
                        let curChats= curObject.chats
                        let dd
                        let objDate=new Date(n)
                        if(isSameDay(today,objDate)){
                            dd="Tänään"
                        }
                        else if(isSameDay(new Date(today-86400000),objDate)){
                            dd="Eilen"
                        }
                        else{
                            dd=n.slice(5,n.length)
                        }
                        return(
                            <div>
                                <div>
                                    <div className={styles.dateContainer}>
                                        <span className={styles.text}>{dd}</span> 
                                        {
                                            filteredDates.includes(n) ?<VscTriangleDown className={styles.dateButton} onClick={()=>handleFilterClick(n)}/> :
                                            <VscTriangleUp className={styles.dateButton} onClick={()=>handleFilterClick(n)}/>
                                        }

                                    </div>
                                    <div className={styles.oneDayChats} style= { !filteredDates.includes(n) ? {"display":"none"} : {}}>
                                        {curChats.map(chat=>{
                                            if(filteredDates.includes(chat.date)){
                                                return null
                                            }
                                            else{
                                                return <div className={styles.oneChat} key={n.id} onClick={()=>handleChatClick(chat)}> {`${chat.chats.length} chattiä ${chat.id.split("Z")[0].split("T")[1].slice(0,5)}`}</div>
                                            }
                                        })}
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        {selectedChat &&
            <div className={styles.currentChat}>
                {chats.find(n=>n.id===selectedChat.id).chats.map((n,i)=>{
                    return <div className={styles[`oneChat${n.role}`]} key={i}> <div className={styles.chatFinal}>{n.content}</div> </div>}
                )}
            </div>
        }
    </div>
    )
}


export default ChatHolder