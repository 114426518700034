import './LoginPage.css'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import {useEffect} from 'react'
import axios from 'axios'
import { url } from "../../url";
import { Helmet, HelmetProvider } from 'react-helmet-async';

const LoginPage = ({loggedIn,setLoggedIn}) =>{
    const [error,setError]=useState(false)
    const navigate=useNavigate()

    useEffect(() => {
        if (loggedIn) {
            navigate('/')
        }
    }, [loggedIn])
    
    const handleSend=()=>{
        if(!error){
            const email=document.getElementById("LoginEmail").value
            const password=document.getElementById("loginPassword").value
            if(email && password){
                axios.post(`${url}/login`,{email:email.toLowerCase(),password:password})
                .then((res)=>{
                    localStorage.setItem('authorizationViralTop', res.data.token);
                    setLoggedIn(true)
                })
                .catch(e=>{
                    setError(prev=>!prev)
                    setTimeout(()=>{
                        setError(prev=>!prev)
                    },3000)
                })
            }
        }
    }
    
        const handlePWchange = (e) => {
            if (e.code === 'Enter') {
                handleSend()
            }
        }

    const handleForgotPWClick=()=>{
        navigate('/forgot');
    }

    return(
        <HelmetProvider>
            <Helmet>
                <link rel="canonical" href="https://emeric.ai/login" />
                <meta name="description" content="Login to Emeric.ai with your email and password"/>
                <title>Login to Emeric.ai</title>
            </Helmet>
        <div className='LoginWholePage'>
            <div className='LoginPage' id="LoginPage">
                <div className='loginTab'>
                    <div className='loginTabInner'>
                        <div className='loginText'>Login</div>
                        <div className='loginEmail'>Email</div>
                        <input className='loginInput' id="LoginEmail" type='text' autoComplete="username"/>
                        <div className='loginPassword'>Password</div>
                        <input type="password" className='loginInput' id="loginPassword" autoComplete="current-password" onKeyPress={handlePWchange}/>
                        <button className='loginButton' onClick={handleSend}>Login</button>
                        <div className='loginError'>{error && <div>Yritä uudestaan</div>}</div>
                        <div className='forgot' onClick={handleForgotPWClick}>Unohditko salasanasi?</div>
                    </div>
                </div>
            </div>
        </div>
        </HelmetProvider>

    )

}

export default LoginPage