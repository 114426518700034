import './Contact.css'
import { IoChatbubblesOutline } from "react-icons/io5";
import { useState } from 'react';
import  axios  from 'axios'
import { url } from "../../url";
import { useNavigate } from 'react-router-dom';

const Contact = () => {
    const [message,setMessage]=useState(false)
    const navigate=useNavigate()

    const redirectToPrivacy = () => {
        return new Promise((resolve, reject) => {
            navigate('/privacy-policy');
            resolve()
        })    
    }

    const handlePrivacyPress=()=>{
        redirectToPrivacy().then(n=>{
            const targetElement = document.getElementById('privacy-policy');
            targetElement.scrollIntoView({ behavior: 'smooth' });
        })        
    }

    const handleFormClick = () =>{
        let name = document.getElementById("contactName").value
        let company = document.getElementById("contactCompany").value
        let phone = document.getElementById("companyPhone").value
        let email = document.getElementById("companyEmail").value
        let message = document.getElementById("companyMessage").value


        const emailBool=!email || !email.includes("@") || !email.split("@")[1].includes(".")
        if(!name || !company || emailBool || !message){
            if(!name){
                document.getElementById("contactName").style.border="1px solid red"

            }
            if(!company){
                document.getElementById("contactCompany").style.border="1px solid red"
            }
            if(emailBool){
                document.getElementById("companyEmail").style.border="1px solid red"
            }
            if(!message){
                document.getElementById("companyMessage").style.border="1px solid red"
            }
        }
        else{

            axios.post(`${url}/email`,{
                name:name,
                company:company,
                phone:phone,
                email:email,
                message:message
            }).then(n=>{
                clearContactField()
                setMessage("Lähetetty!")
                setTimeout(()=>{
                    setMessage("")
                },3000)
            })
            .catch(n=>{
                clearContactField()
                setMessage("Error!")
                setTimeout(()=>{
                    setMessage("")
                },3000)
            })
        }
    }
    function clearContactField(){
        document.getElementById("contactName").value=""
        document.getElementById("contactCompany").value=""
        document.getElementById("companyPhone").value=""
        document.getElementById("companyEmail").value=""
        document.getElementById("companyMessage").value=""
    }

    const nameChange = () => {
        document.getElementById("contactName").style.border="1px solid #4F4F4F"
    }
    const emailChange = () => {
        document.getElementById("companyEmail").style.border="1px solid #4F4F4F"
    }
    const companyChange = () => {
        document.getElementById("contactCompany").style.border="1px solid #4F4F4F"
    }
    const messageChange = () => {
        document.getElementById("companyMessage").style.border="1px solid #4F4F4F"
    }

    return (
        <div className="Contact" id="Contact">
            <div className='ContactLogo'>
                <div className='ContactLogoText'>
                    Ota meihin <br/> yhteyttä!
                </div>
                <IoChatbubblesOutline className="contactIcon"/>
            </div>
            
            <div className='contactTab'>
                    <div >Nimi</div>
                    <input placeholder='*' id="contactName" onChange={nameChange}/>
                    <div >Yritys</div>
                    <input placeholder='*' id="contactCompany" onChange={companyChange}/>
                    <div >Puh.</div>
                    <input id="companyPhone"/>
                    <div>Email</div>
                    <input placeholder='*' id="companyEmail" onChange={emailChange}/>  
                    <div>Viestisi meille</div>
                    <textarea placeholder='*' id="companyMessage" onChange={messageChange}/>
                    
                    <span onClick={()=>handlePrivacyPress()} className="tietosuojaselostaText"style={{fontSize:"16px",cursor:"pointer",textDecoration:"underline",margin:"6px"}}>
                        Tietosuojaseloste
                    </span>
                    
                    <button onClick={handleFormClick}>Lähetä</button>
                    {message &&
                        <span className='sentMsg' style={{"color":message === "Lähetetty!" ? "green" :"red"}}>
                            {message}</span>}
            </div>
 
        </div>
    );
};

export default Contact;
