import { useState } from 'react'
import './Forgot.css'
import axios from "axios"
import { url } from "../../url";
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Forgot = ()=>{
    const [isSent,setIsSent]=useState(false)
    const [error,setError] = useState(false)

    const handleSend=()=>{
        axios.post(`${url}/forgot`,{email:document.getElementById("forgotInput").value.toLowerCase()})
        .then(n=>{
            document.getElementsByClassName("forgotInput").value=""
            setIsSent(true)
        })
        .catch(n=>{
            document.getElementsByClassName("forgotInput").value=""
            setError(prev=>!prev)
            setTimeout(()=>{
                setError(prev=>!prev)
            },2000)
        })


    }

    const handleEmailChange = (e) => {
        if (e.code === 'Enter') {
            handleSend()
        }
    }


    return(
    <HelmetProvider>
        <Helmet>
            <link rel="canonical" href="https://emeric.ai/forgot" />
            <meta name="description" content="Reset your password via email"/>
            <title>Forgot password?</title>
        </Helmet>
        <div style={{"display":"flex","flexDirection":"column",marginBottom:"200px"}}>
            {!isSent &&
                <div className='forgotContainer'>
                    <div className="forgotContainer2">
                        <div className='forgotCont2'>
                            <div className='forgotemail'>Email:</div>
                            <input className='forgotInput' id="forgotInput" onKeyPress={handleEmailChange}/>
                            <button onClick={handleSend} className='forgotButton'>send</button>
                        </div>
                        {error &&
                            <div style={{"color":"red"}}>Sähköpostilla ei löydy käyttäjää</div>
                        }
                    </div>
            </div>
            }
            {isSent &&
                <div className='forgotEmailSentContainer'>
                    <div className='forgotEmailSent'>
                        Linkki uuden salasanan luontiin on lähetetty sähköpostiisi
                    </div>
            </div>
            }
        </div>
    </HelmetProvider>
    )
}
export default Forgot