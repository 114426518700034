import './why-us.css'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import SmartToyIcon from '@mui/icons-material/SmartToy';

const iconDict={
    1:<CheckCircleOutlineIcon className='ConsoleIcon'/>,
    2:<LocalAtmIcon className='ConsoleIcon'/>,
    3:<SmartToyIcon className='ConsoleIcon'/>
}
const Console= ({title,texts,icon}) =>{

    return(
        <div className='Console'>
            <div className='ConsoleHeader'>
                {title}
            </div>
            <div className='consoleTextArea'>
                {texts.map((text,i)=>{
                    return (
                        <div className='consoleTextContainer' key={i}>
                            <ArrowRightAltIcon className='containerLogo'/>
                            <div className='consoleText'>{text}</div> 
                        </div>
                        )
                    
                })}
            </div>
            <div className='consoleFooter'>
            {iconDict[icon]}

            </div>


        </div>
    )
}
const lista=[
    {title:"Laatu",
    texts:["Tuottemme rakennetaan yksilöllisesti juuri teille!","Emme tuota valmiiksi rakennettuja massatuotteita.",
    "Kehitämme ja parannamme tuotettamme jatkuvasti."],
    icon:1

},
    {title:"Hinta",
    texts:["Älä tuhlaa tuhansia heikompiin ratkaisuihin!","Tekoälybotti on kaikista kustannustehokkain ratkaisu!",
        "Leikkaa kuluja samalla kun parannat palvelua."],
    icon:2
    },
    {title:"Tekoäly",texts:["Saatavilla 24/7.","Säännölliset vastaukset.","Luonnollinen kommunikaatio.","Nopeat vastaukset sekunneissa!",
    "Tyytyväisemmät asiakkaat, enemmän myyntiä!"],
    icon:3
}
]


const Why2 = ()=>{
    return(
        <div className='Why2'>
            <h3>
                Miksi Emeric.ai?
            </h3>
            <div className='Why2Body'>
                {lista.map((n,i)=>{
                    return <Console key={i} title={n.title} texts={n.texts} icon={n.icon}/>
                })}

            </div>
        </div>
    )
}

export default Why2