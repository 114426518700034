import Contact from '../../components/contact/Contact'
import './ChatBotsWindow.css'
import { FaRegCheckCircle } from "react-icons/fa";
import { FaHandshake } from "react-icons/fa";
import { FaCode } from "react-icons/fa6";
import { FaBookOpen } from "react-icons/fa";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { FaRegSmile } from "react-icons/fa";
import { FaChartLine } from "react-icons/fa";
import { FaSortAmountDownAlt } from "react-icons/fa";
import { FaCheck } from "react-icons/fa";
import { FaThumbsDown } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import { Helmet, HelmetProvider } from 'react-helmet-async';



const ChatBotsWindow = () =>{
    const iconList=[
        <FaRegCheckCircle className='icon22'/>,
        <FaHandshake className='icon22'/>,
        <FaCode className='icon23'/>,
        <FaBookOpen className='icon23'/>,
        <ArrowRightAltIcon className='icon22'/>,
        <EventAvailableIcon className='icon20'/>,
        <FaRegSmile className='icon24'/>,
        <FaChartLine className='icon22'/>,
        <FaSortAmountDownAlt className='icon22'/>,
        <FaCheck className='lastIcon'/>,
        <FaThumbsDown className='lastIcon'/>,
        <ImCross className='lastIcon'/>
    ]
    const lista=[
        {
            title:"Mihin chatbottimme soveltuu?",
            texts:[
                {icon:0,text:"Asiakaspalvelu"},
                {icon:0,text:"Myynti"},
                {icon:0,text:"Liidien kerääminen"}
            ],
        },
        {
            title:"Mitä hyötyä chatbotista on teille?",
            texts:[
                {icon:1,text:"Parempaa asiakaspalvelua"},
                {icon:7,text:"Enemmän myyntiä"},
                {icon:8,text:"Vähemmän kuluja"}
            ],
        },
        {
            title:"Miten chatbot asennetaan?",
            texts:[
                {icon:2,text:"Luovutamme teille sähköpostilla lähdekoodin, joka lisätään sivuillenne."},
                {icon:3,text:"Luovutuksen yhteydessä annamme ohjeet siitä, miten chatbot asennetaan verkkosivuillenne."}
            ],
        }
    ]

    const lista2=[
        {
            title:"TekoälyBotti",
            texts:[
                "Saatavilla 24/7.",
                "Nopeat ja kattavat vastaukset sekunneissa.",
                "Säännölliset vastaukset.",
                "Kustannustehokas ratkaisu.",
                "Useampi käyttäjä samaan aikaan.",
                "Avoimet kommentit.",
                "Luonnollinen, ihmisen kaltainen kommunikaatio."
            ],
            icon:9
        },
        {
            title:"Muut Botit",
            texts:[
                "Ennalta määrätyt vastaukset.",
                "Kömpelöt keskustelukortit.",
                "Rajallinen kyky vastata asiakkaan tarpeisiin.",
                "Korkea hinnoittelu."
            ],
            icon:10
        },
        {
            title:"Ihminen",
            texts:[
                "Riippuvainen chatin aukioloajoista.",
                "Asiakas joutuu odottamaan vastausta jopa minuutteja.",
                "Vastaukset suppeita, epäsäännöllisiä & epätarkkoja.",
                "Rajallinen ymmärrys tietokannasta.",
                "Korkeat kustannukset (palkka, koulutus, muut maksut)."
            ],
            icon:11
        }
    ]

    return(
        <HelmetProvider>
            <Helmet>
                <link rel="canonical" href="https://emeric.ai/chatbots" />
                <meta name="description" content="Emeric.ai chatbotit luovat liidejä, huolehtii asiakkastasi sekä toimii nopeasti 24/7"/>
                <title>Emeric.ai - Chatbotti firmallesi</title>
            </Helmet>

            <div className='ChatBotsWindow'>

                <div className="chatbotTableHolder" id="chatbotTableHolder">
                    <div className="chatBotsTable" id="chatBotsTable">
                        {lista.map((n,i)=>{
                            return(
                                <div className='oneTable' id={`oneTable${i}`} key={i}>
                                    <div className='tableTitle'>{n.title}</div>
                                        <div className="tableItems">
                                            {n.texts.map((k,i2)=>{
                                                return(
                                                    <div className='tableRow' key={i2}> 
                                                        {iconList[k.icon]}
                                                        <div className='tableText'>{k.text}</div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                </div>
                            )
                        })}
                    </div>

                </div>


                <div className='vsHeadline'>Chatbottimme vs muut vaihtoehdot</div>
                <div className='table2'>
                    {lista2.map((n,i)=>{
                                return(
                                    <div key={i}>
                                        <div className='tabletitle2'>{n.title}</div>
                                        <div className='oneTable2'>
                                            {n.texts.map((k,i2)=>{
                                                return(
                                                    <div className='tableRow2' id={`tableicon${i}`} key={i2}> 
                                                        {iconList[n.icon]}
                                                        <div className='tableText2'>{k}</div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                )
                            })}  

                </div>
                <Contact/>
            </div>
        </HelmetProvider>

    )

}

export default ChatBotsWindow