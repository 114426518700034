import './Footer.css'
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import InfoIcon from '@mui/icons-material/Info';
import { useNavigate } from 'react-router-dom';
import kuva from '../../eai.png'

const Footer = ()=>{
    const navigate=useNavigate()

    const redirectToPrivacy = () => {
        return new Promise((resolve, reject) => {
            navigate('/privacy-policy');
            resolve()
        })    
    }

    const handlePrivacyPress=()=>{
        redirectToPrivacy().then(n=>{
            const targetElement = document.getElementById('privacy-policy');
            targetElement.scrollIntoView({ behavior: 'smooth' });
        })        
    }

    return (
    <footer className='Footer'>
        <div className='firstHalf'>
            <div className='footerLogoContainer'>
                <img alt="Emeric.ai logo" title="Emeric.ai logo" loading='lazy' src={kuva}></img>
                <div className='footerLogoText2'>est. 2023</div>
            </div>
            <div className='privacy' onClick={()=>handlePrivacyPress()}>Privacy policy</div>

        </div>

        <div className='secondHalf'>
            <MailOutlineIcon className="icon" fontSize="large" />
            <a href="mailto:info@emeric.ai">info@emeric.ai</a>
            <LocalPhoneIcon className="icon" fontSize="large" />
            <div>0407441877</div>
            <InfoIcon className="icon" fontSize="large"/>
            <div>Y-tunnus: 3372940-4</div>
        </div>
    </footer>
    )
}


export default Footer