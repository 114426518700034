import ChartHolder from './ChartHolder/index.js'
import ChatHolder from './ChatHolder/index.js'
import Contact from '../../components/contact/Contact.js'
import kuva from '../../dash.webp';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Dashboard = ({chats,loggedIn}) => {
    return(
        <HelmetProvider>
            <Helmet>
                <link rel="canonical" href="https://emeric.ai/dashboard" />
                <meta name="description" content="Dashboardista voit seurata asiakaspalvelu kysymyksiä ja nähdä tilastoja chateista"/>
                <title>Emeric.ai - Dashboard</title>
                <link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
     rel="stylesheet"></link>

            </Helmet>
            {(loggedIn && chats) &&
                <div style={{fontWeight:"300",display:"flex",fontFamily:"Poppins",paddingTop: "80px"}}>
                    <ChartHolder chats={chats.filter(n=>!n.local)} />
                    <ChatHolder chats={chats}/>
                </div>
            }
            {!loggedIn &&
                <div style={{paddingTop: "60px",fontSize: "20px"}}>
                    <div style={{"display":"flex","alignItems":"center","flexDirection":"column","fontFamily":"Poppins","marginRight":"20px","marginLeft":"20px","fontWeight":"370"}}>
                        <img style={{marginTop:"30px",width:"70%",marginBottom:"20px"}} alt="Dashboard to access your chats" title="You can see charts and read your customer serive chats on the dashboard" src={kuva}
                        loading="lazy" />
                        Näet tässä chatbotin keskustelut asiakkaidesi kanssa ja tilastoja chateistasi.
                    </div>
                    <Contact/>
                </div>
            }
        </HelmetProvider>
    )
}


export default Dashboard
